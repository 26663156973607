import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import TagsForAll from '../components/Common/TagsForAll'
import ZaaviaPrivacyPolicy from '../components/TermsAndPolicy/ZaaviaPrivacyPolicy'
import { Helmet } from 'react-helmet'

const PrivacyPolicy = () => {
    return (
        <Layout>
        <TagsForAll pageTitle="Privacy Policy" />
        <Helmet>
            <meta name='robots' content='index, follow' />
            <link rel="canonical" href="https://zaavia.net/privacy-policy" />
        </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="Privacy Policy" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Privacy Policy" 
            />
            <div className='terms-container'>
                <ZaaviaPrivacyPolicy />
            </div>
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;
import React from 'react'

 const ZaaviaPrivacyPolicy = () => {
  return (
    <div className='modal-document pt-5 pb-5 '>
      <hr />
      <div>
      <p>Zaavia assures you that we respect everyone's privacy, and we do not collect or use any personal information from our software products. We do not use cookies or any tracking mechanisms to collect information about users from our software products.</p>
      <p>Our website may contain links to external sites, please note that we are not responsible for the privacy practices of those external sites. In the event of a change in our practices, we will update this Privacy Policy accordingly.</p>
      </div>
      <hr />
      <div style={{textAlign:"end"}}>
        <span>Last Updated:  November 2023</span>
      </div>
    </div>
  )
}
export default ZaaviaPrivacyPolicy